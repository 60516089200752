import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { sendMessage, uploadImage } from '../actions';
import { AppDispatch } from '../store';
import { chatTypes } from '../constants/actionTypes';
import ImageLogo from '../assets/svgs/ImageLogo';
import UploadLogo from '../assets/svgs/UploadLogo';
import SendLogo from '../assets/svgs/SendLogo';
import RemoveImageLogo from '../assets/svgs/RemoveImageLogo';

const Container = styled.div`
  position: fixed;
  bottom: 0px;
  box-sizing: border-box;
  max-width: 600px;
  width: 100%;
  border-top: 1px solid #dbcbcb;
  background: white;
  z-index: 2;
`;

const TextInput = styled.textarea<{ isFile: boolean }>`
  font-size: 16px;
  width: 100%;
  padding: 10px;
  border: none;
  outline: none;
  resize: vertical;
`;

const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const CancelFile = styled.span`
  cursor: pointer;
  padding: 8px 12px 6px;
`;

const FileSizeError = styled.div`
  padding: 10px;
  background: red;
  color: white;
  position: fixed;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
`;

const UploadContainer = styled.label`
  padding: 8px 12px 6px;
  cursor: pointer;

  input {
    display: none;
  }
`;

const SendContainer = styled.label`
  padding: 8px 12px;
  cursor: pointer;

  input {
    display: none;
  }
`;

const ChatInput = () => {
  const dispatch: AppDispatch = useDispatch();
  const sessionChatId = useSelector(
    (state: any) => state.chatReducer.sessionChatId
  );
  const number = useSelector((state: any) => state.chatReducer.number);
  const { weddingId: weddingIdParams } = useParams();
  const weddingId =
    useSelector((state: any) => state.chatReducer.wedding)?.id ||
    weddingIdParams;
  const [message, setMessage] = useState('');
  const [showError, setShowError] = useState(false);
  const [file, setFile] = useState<any>(null);

  const chatId = `${number}${sessionChatId}`;

  const getInputValue = () => {
    if (file) {
      return file.name;
    }

    return message;
  };

  const handleFileChange = (e: any) => {
    const file = e.target.files[0];
    // File size less than 3mb
    if (file?.size > 3000000) {
      setShowError(true);
      setTimeout(() => {
        setShowError(false);
      }, 3000);
    } else {
      setFile(file);
    }
  };

  const uploadFile = () => {
    try {
      const reader: any = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result
          .replace('data:', '')
          .replace(/^.+,/, '');

        dispatch(uploadImage(chatId, base64String));
        setFile(null);
      };
      reader.readAsDataURL(file);
    } catch (error) {
      console.log('upload error', error);
    }
  };

  const onEnterPress = (e: any) => {
    if (e.keyCode == 13 && e.shiftKey == false) {
      e.preventDefault();
      onSubmit(e);
    }
  };

  const onSubmit = (e: any) => {
    e.preventDefault();
    if (file) {
      uploadFile();
    } else {
      // dispatch({
      //   type: chatTypes.SEND_MESSAGE,
      //   payload: {
      //     isSender: true,
      //     text: message,
      //   },
      // });
      dispatch(sendMessage(weddingId, message));
    }
    setMessage('');
  };

  return (
    <Container>
      {showError && (
        <FileSizeError>
          File size too large. Should be less than 3mb
        </FileSizeError>
      )}
      <form onSubmit={onSubmit}>
        <TextInput
          value={getInputValue()}
          isFile={!!file}
          disabled={!!file}
          placeholder={'Type a message'}
          rows={1}
          onKeyDown={onEnterPress}
          onChange={(e) => {
            setMessage(e.target.value);
          }}
        />
        <Actions>
          {/* {file ? (
            <CancelFile
              onClick={() => {
                setFile(null);
                setMessage('');
              }}
            >
              <RemoveImageLogo />
            </CancelFile>
          ) : (
            <UploadContainer>
              <input type="file" accept="image/*" onChange={handleFileChange} />
              <ImageLogo />
            </UploadContainer>
          )} */}
          <SendContainer>
            <input disabled={!message && !file} type="submit" />
            {file ? <UploadLogo /> : <SendLogo />}
          </SendContainer>
        </Actions>
      </form>
    </Container>
  );
};

export default ChatInput;
